import React from 'react'

import { Link } from 'gatsby'

const Worksnav = ({children}) => {
  let style = {
    color: "black"
  };  

    return(
    <nav>
    <p class="nendai">{children}</p>
    <ul>
      <li><Link to="/portrait2020" style={style}>-2020</Link></li>
      <li><Link to="/portrait2019">-2019</Link></li>
      <li><Link to="/portrait2018">-2018</Link></li>
      <li><Link to="/portrait2017">-2017</Link></li>
      <li><Link to="/portrait2016">-2016</Link></li>
      <li><Link to="/portrait2015">-2015</Link></li>  
    </ul>
    </nav>
    )
}

export default Worksnav